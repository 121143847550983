// import {Country} from './country';
import {BaseModel} from './base-model';

// import {RepaymentRecordStatic} from '../modules/loan/model/repayment-record';

class DataOption extends BaseModel {
  value: string;
  label: string;

  optionValue() {
    return this.value;
  }

  optionLabel() {
    return this.label;
  }

}

export class BaseDataOptions {
  rawValues: any[] = [];
  data = [];

  constructor(values: any[] = []) {
    this.rawValues = values;
    this.data = [];
    values.forEach((val) => {
      this.makeInstance(val);
    });
  }

  makeInstance(val) {
    if (typeof val === 'string') {
      this.data.push((new DataOption({
        value: val,
        label: val
      })));
    } else {
      this.data.push((new DataOption(val)));
    }
  }


}

export class DataOptions extends BaseDataOptions {
  data: DataOption[];

  push(data) {

    let _d = data;
    if (!(data instanceof DataOption)) {
      _d = new DataOption(_d);
    }
    this.data.push(_d);

  }

  static range(min, max, step = 1): DataOptions {

    const data = [];
    for (let i = min; i <= max; i += step) {
      data.push({value: i, label: i});
    }
    return new DataOptions(data);

  }

  static numbers(numbers: number[]): DataOptions {
    const data = [];
    for (let i = 0; i < numbers.length; i++) {
      data.push({value: numbers[i], label: numbers[i]});
    }
    return new DataOptions(data);
  }

  static yesno(): DataOptions {
    return new DataOptions([
      {
        label: 'No',
        value: 0,
      },
      {
        label: 'Yes',
        value: 1,
      },
    ]);
  }


}

// class CountryOptionItem extends Country {
//
//     optionValue() {
//         return this.id;
//     }
//
//     optionLabel() {
//         return this.name;
//     }
// }
//
// class CountryCodeOptionItem extends Country {
//     optionValue() {
//         return this.code;
//     }
//
//     optionLabel() {
//         return this.code + ' ' + this.name;
//     }
// }


// export class CountryOptions extends BaseDataOptions {
//     data: CountryOptionItem[];
//
//     makeInstance(val) {
//         this.data.push((new CountryOptionItem(val)));
//     }
// }
//
// export class CountryCodeOptions extends BaseDataOptions {
//     data: CountryCodeOptionItem[];
//
//     makeInstance(val) {
//         this.data.push((new CountryCodeOptionItem(val)));
//     }
// }
