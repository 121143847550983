import {catchError, map} from 'rxjs/operators';

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';

import {environment} from '../../environments/environment';
import {GlobalService} from './global.service';
import {ResponseBody} from './response-body';

import {BehaviorSubject, Observable, of} from 'rxjs';
import {User} from './user';

@Injectable()
export class UserService {
  redirectURL = '';
  loggedIn = false;

  private userDataSource = new BehaviorSubject({});
  public observeUserData = this.userDataSource.asObservable();
  parsedPermission: any[];

  constructor(
    private globalService: GlobalService,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private http: HttpClient,
    // @Inject(WINDOW) private window: Window,
    // @Inject(LOCAL_STORAGE) private localStorage: any
  ) {

    this.loggedIn = this.isLoggedIn();

  }

  setAccessToken(access_token) {

    localStorage.setItem(
      environment.tokenName,
      access_token
    );
    this.loggedIn = true;

  }

  public login(username, password) {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/login'),
        JSON.stringify({
          username: username,
          password: password,
          role: 'buyer',
        }),
        {
          headers: headers
        }
      ).pipe(

        map(response => {

          if (response.success) {

            this.setAccessToken(response.data['access_token']);
            // this.fetchServerData();

            this.setPermissions(response.data['permissions']);
            // this.parsedPermission = response.data['permissions'];

          } else {

            localStorage.removeItem(environment.tokenName);
            this.loggedIn = false;

          }

          return response;

        }),
        catchError(GlobalService.handleError)

      );
  }

  setPermissions(permissions) {
    // sessionStorage.setItem('permissions', JSON.stringify(permissions));
    this.parsedPermission = permissions;
  }

  public changePassword(id,password) {
    const headers = GlobalService.getHeaders();

    return this.http
      .put<ResponseBody>(
        GlobalService.getUrl('/user/'+id+'/change-password'),
        JSON.stringify({
          password: password
        }),
        {headers: headers}
      ).pipe(
        map(response => {
          if (response.success) {
          } else {
          }
          return response;
        }),
        catchError(GlobalService.handleError));
  }


  public signup(data) {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/signup'),
        data,
        {headers: headers}
      ).pipe(
        map(response => {
          if (response.success) {

            if (typeof response.data['access_token'] !== 'undefined') {

              localStorage.setItem(
                environment.tokenName,
                response.data['access_token']
              );
              this.loggedIn = true;

            }

          } else {
          }
          return response;
        }),
        catchError(GlobalService.handleError));
  }

  public validateSignup(data) {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/validate-signup'),
        data,
        {headers: headers}
      ).pipe(
        map(response => {
          if (response.success) {

            if (typeof response.data['access_token'] !== 'undefined') {

              localStorage.setItem(
                environment.tokenName,
                response.data['access_token']
              );
              this.loggedIn = true;

            }

          } else {
          }
          return response;
        }),
        catchError(GlobalService.handleError));
  }

  public signupConfirm(id, auth_key) {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/confirm'),
        {
          id: id, auth_key: auth_key
        },
        {headers: headers}
      ).pipe(
        map(response => {
          return response;
        }),
        catchError(GlobalService.handleError));
  }

  public passwordResetRequest(email) {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/password-reset-request'),
        {
          email: email,
          role: 'buyer'
        },
        {headers: headers}
      ).pipe(
        map(response => {
          if (response.success) {
          } else {
          }
          return response;
        }),
        catchError(GlobalService.handleError));
  }

  public passwordResetOtpTokenVerification(email,otp_token, otp_code) {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/password-reset-otp-token-verification'),
        {email,otp_token, otp_code},
        {headers: headers}
      ).pipe(
        map(response => {
          if (response.success) {
          } else {
          }
          return response;
        }),
        catchError(GlobalService.handleError));
  }

  public passwordResetTokenVerification(token) {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/password-reset-token-verification'),
        {token: token},
        {headers: headers}
      ).pipe(
        map(response => {
          if (response.success) {
          } else {
          }
          return response;
        }),
        catchError(GlobalService.handleError));
  }

  public passwordReset(token, password) {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/password-reset'),
        {
          token: token,
          password: password
        },
        {headers: headers}
      ).pipe(
        map(response => {
          if (response.success) {
          } else {
          }
          return response;
        }),
        catchError(GlobalService.handleError));
  }

  public logout(): void {
    localStorage.removeItem(environment.tokenName);
    this.loggedIn = false;
  }

  public getToken(): any {
    return localStorage.getItem(environment.tokenName) || '';
  }

  public getId(): any {
    const jwtVal = this.getJWTValue();
    if (jwtVal && jwtVal.data && jwtVal.data.id) {
      return jwtVal.data.id;
    }
    return null;

  }

  public unauthorizedAccess(error: any): void {
    this.logout();
    this.router.navigate(['/login']);
  }

  public isLoggedIn(): boolean {
    return this.jwtHelper.isTokenExpired(this.getToken()) !== true;
  }


  public getJWTValue(): any {
    if (this.isLoggedIn()) {
      const token = this.getToken();
      return this.jwtHelper.decodeToken(token);
    } else {
      return null;
    }
  }

  public getData(fromServer?: boolean): Observable<User> {

    if (fromServer) {
      this.fetchServerData();
    } else {
      const _userData = localStorage.getItem('user');
      if (_userData) {
        const user = JSON.parse(_userData);
        this.userDataSource.next(user);
      }
    }
    return this.observeUserData.pipe(map(response => {
      return new User(response);
    }));

  }

  public setLocalData(userData: any) {
    this.userDataSource.next(userData);
    localStorage.setItem(
      'user',
      JSON.stringify(userData)
    );
  }

  public fetchServerData() {
    if (this.loggedIn) {
      this.getMe({
        expand: 'fundSummary'
      }).subscribe(user => {
        this.setLocalData(user);
      });
    }
  }

  setStoreVariable(key, val) {
    localStorage.setItem(key, val);
  }

  getStoreVariable(key): string {
    return localStorage.getItem(key);
  }

  public getMe(query = {}): Observable<User> {

    const headers = GlobalService.getHeaders();
    return this.http
      .get<ResponseBody>(
        GlobalService.getUrl('/user/me', query),
        {headers: headers}
      ).pipe(
        map(response => {
          const user = new User(response.data)
          this.setLocalData(user);
          return user;
        }),
        catchError(GlobalService.handleError));

  }


  updateUser(userData, headers = null): Observable<User> {

    if (headers == null) {
      headers = GlobalService.getHeaders();
    }

    return this.http
      .put<ResponseBody>(
        GlobalService.getUrl('/user/me'),
        userData,
        {headers: headers}
      ).pipe(
        map(response => {
          return new User(response.data);
        }),
        catchError(GlobalService.handleError));
  }



  updateUserKyc(userData): Observable<User> {
    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/me/kyc', {expand: 'businessInfo,personalInfo'}),
        userData,
        {headers: headers}
      ).pipe(
        map(response => {
          return (new User(response.data));
        }),
        catchError(GlobalService.handleError));
  }


  verifyOnboardingToken(data): Observable<any> {

    const token = data['token'];
    const email_address = data['email_address'];

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/onboarding-token-verify'),
        {token,email_address}
      ).pipe(
        map(response => {
          this.setAccessToken(response.data.access_token);
          return (new User(response.data.user));
        }),
        catchError(GlobalService.handleError)
      );
  }

  sendEmailOtp(): Observable<any> {

    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/onboarding-generate-otp'),
        {},
        {headers: headers}
      ).pipe(
        map(response => {
          return response.data;
        }),
        catchError(GlobalService.handleError)
      );

  }

  confirmOnboardingOtp(otp_token, otp_code): Observable<any> {

    const headers = GlobalService.getHeaders();

    return this.http
      .post<ResponseBody>(
        GlobalService.getUrl('/user/onboarding-verify-otp'),
        {otp_token, otp_code},
        {headers: headers}
      ).pipe(
        map(response => {
          return response.data;
        }),
        catchError(GlobalService.handleError)
      );

  }


  updateUserDummy(userData): Observable<User> {
    const user = new User();
    return of(user);
  }

  getPermissions(): any[] {
    if (this.globalService.isLoggedIn()) {
      // if (this.parsedPermission == null) {
      //     this.parsedPermission = JSON.parse(sessionStorage.getItem('permissions'));
      // }
      return this.parsedPermission;
    } else {
      return [];
    }
  }

  can(permissions: string[]) {

    // admin must always can for anything
    permissions.push('buyer');

    const allPermissions = this.getPermissions();
    let found = false;
    if (allPermissions) {
      allPermissions.forEach((_permission, index) => {
        permissions.forEach((_permission2, index2) => {
          if (!found && _permission === _permission2) {
            found = true;
          }
        });
      });
    }
    return found;

  }



}
