import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {Invoice} from "./invoice";
import {GlobalService} from "../../../model/global.service";
import {ResponseBody} from "../../../model/response-body";
import {InvDocumentList} from "./inv-document-list";
import {InvDocument} from "./inv-document";
import {BaseModel} from "../../../model/base-model";


export class Project extends BaseModel {
  reference_no: string;
  progress: string;
  funding_start_date: string;
}

export class Loan extends BaseModel {
  id: string;
  status_label: string;
  reference_no: string;
}


@Injectable({
  providedIn: 'root'
})
export class InvDocumentService {

  constructor(
    private http: HttpClient
  ) {
  }


  getList(query = {}): Observable<InvDocumentList> {

    if (typeof query['expand'] == 'undefined') {
      query['expand'] = 'data';
    }
    query['format'] = 'default';

    const headers = GlobalService.getHeaders();
    return this.http.get<ResponseBody>(
      GlobalService.getUrl('/invoice/document', query), {
        headers: headers
      }
    ).pipe(
      map(response => {
        return new InvDocumentList(response.data)
      }),
      catchError(GlobalService.handleError)
    )

  }


  getById(id): Observable<InvDocument> {


    return this.http.get<ResponseBody>(
      GlobalService.getUrl('/invoice/document/' + id, {expand: ''}),
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new InvDocument(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }

  create(data): Observable<Invoice> {

    const headers = GlobalService.getHeaders();
    return this.http.post<ResponseBody>(
      GlobalService.getUrl('/invoice/document'),
      data, {headers: headers}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }


  update(id, data): Observable<Invoice> {

    return this.http.put<ResponseBody>(
      GlobalService.getUrl('/invoice/document/' + id), data,
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }

  getOverdueSummary(): Observable<any[]> {

    const headers = GlobalService.getHeaders();
    return this.http.get<ResponseBody>(
      GlobalService.getUrl('/invoice/document/overdue-summary'), {
        headers: headers
      }
    ).pipe(
      map(response => {
        return response.data;
      }),
      catchError(GlobalService.handleError)
    )

  }


  submitRequestEarlyRepayment(id, data): Observable<Invoice> {

    return this.http.put<ResponseBody>(
      GlobalService.getUrl('/invoice/document/' + id + '/request-early-repayment'), data,
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }

  reset(id): Observable<Invoice> {

    return this.http.put<ResponseBody>(
      GlobalService.getUrl('/invoice/document/' + id + '/reset'), {},
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }


  calculateDiscount(data): Observable<any> {

    return this.http.post<ResponseBody>(
      GlobalService.getUrl('/loan/calculate-discount'),
      data,
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }

  requestPaymentBatch(documents): Observable<Invoice> {

    return this.http.post<ResponseBody>(
      GlobalService.getUrl('/invoice/document/request-payment', {expand: 'loan'}),
      {
        documents
      },
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }

  publishInvoice(id, data): Observable<Invoice> {

    return this.http.put<ResponseBody>(
      GlobalService.getUrl('/invoice/document/' + id + '/publish', {expand: 'loan'}),
      data,
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }

  downloadDisclosure(id): Observable<any> {

    return this.http.get<ResponseBody>(
      GlobalService.getUrl('/invoice/document/disclosure-statement/' + id),
      {headers: new HttpHeaders({
          'Content-Type': 'application/pdf',
          'Authorization': 'Bearer ' + GlobalService.getToken()
        })}
    ).pipe(
      map(response => {
        return response.data;
      }),
      catchError(GlobalService.handleError)
    )

  }


  cancel(id): Observable<Invoice> {

    return this.http.put<ResponseBody>(
      GlobalService.getUrl('/invoice/document/' + id + '/cancel'),
      {},
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }

  reject(id, data): Observable<Invoice> {

    return this.http.put<ResponseBody>(
      GlobalService.getUrl('/invoice/document/' + id + '/reject'),
      data,
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }

  approve(id, data): Observable<Invoice> {

    return this.http.put<ResponseBody>(
      GlobalService.getUrl('/invoice/document/' + id + '/approve'),
      data,
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(response => {
        return new Invoice(response.data);
      }),
      catchError(GlobalService.handleError)
    )

  }

  getMonthlySummary(query = {}): Observable<any[]> {

    if (typeof query['expand'] == 'undefined') {
      query['expand'] = 'data';
    }

    const headers = GlobalService.getHeaders();
    return this.http.get<ResponseBody>(
      GlobalService.getUrl('/invoice/document/monthly-summary', query), {
        headers: headers
      }
    ).pipe(
      map(response => {
        return response.data
      }),
      catchError(GlobalService.handleError)
    );

  }

  getAllFinanceDownload(extendedQueries?: any, cb = null) {

    let queries = {};
    if (extendedQueries) {
      queries = Object.assign(queries, extendedQueries);
    }

    return this.http.get<ResponseBody>(
      GlobalService.getUrl('/invoice/document?download=1', queries),
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(
        response => {
          return response;
        }
      ),
      catchError(GlobalService.handleError)
    ).subscribe(
      response => {

        if (cb) {
          cb()
        }
        this.downLoadFile1(response.data, 'text/csv', 'finance_report')

      }
    );

  }

  getAllInvoicesDownload(extendedQueries?: any, cb=null, filename=null) {

    let queries = {};
    if (extendedQueries) {
      queries = Object.assign(queries, extendedQueries);
    }
    queries['format'] = 'default';

    return this.http.get<ResponseBody>(
      GlobalService.getUrl('/invoice/document?filter=disbursed&download=1', queries),
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(
        response => {
          return response;
        }
      ),
      catchError(GlobalService.handleError)
    ).subscribe(
      response => {

        if (cb) {
          cb()
        }
        this.downLoadFile1(response.data, 'text/csv', (filename || queries['type'] || 'invoices'))

      }
    );

  }
  downLoadFile1(data: any, type: string, filename: string) {

    // const a = document.createElement("a") as Anch;
    const a = document.createElement("a");
    document.body.appendChild(a);
    // a.style = "display: none";

    const blob = new Blob([data], {type: "text/csv"}),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'invoice_' + filename + '.csv';

    a.click();
    window.URL.revokeObjectURL(url);


  }

  parseImportFile(file, onError=null): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    // formData.append('company_id', company_id);

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + GlobalService.getToken()
    });

    const req = new HttpRequest('POST',
      GlobalService.getUrl('/invoice/document/parse-import-file'),
      formData,
      {
        headers: headers,
        reportProgress: true
      });

    const progress = new Subject<any>();

    this.http.request<ResponseBody>(req).pipe(
      catchError(GlobalService.handleError)
    ).subscribe(event => {

      if (event.type === HttpEventType.UploadProgress) {

        const percentDone = Math.round(100 * event.loaded / event.total);
        progress.next(percentDone);

      } else if (event instanceof HttpResponse) {

        progress.next(event.body.data);
        // Close the progress-stream if we get an answer form the API
        // The upload is complete
        progress.complete();

      }

    }, error => {

      if (onError) {
        onError(error)
      }

    });

    return progress.asObservable();
  }
  saveBatch(data) :Observable<any> {
    return this.http.post<ResponseBody>(
      GlobalService.getUrl('/invoice/document/save-batch'),
      data,
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(
        response => {
          return response.data;
        },
        catchError(GlobalService.handleError)
      )
    );
  }
}
