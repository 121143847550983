<div *ngIf="user?.id" class="container-fluid">
  <div class="row">
    <!-- TopBar -->
    <div id="topbar-wrapper" class="shadow-sm">
        <div id="topbar-container">
            <div class="row h-100 align-items-center">
                <div class="col-6">
                    <div class="text-red">
                        <b class="f18" *ngIf="mainRoute === 'dashboard'">DASHBOARD</b>
                        <b class="f18" *ngIf="mainRoute === 'account'">PROFILE</b>
                        <b class="f18" *ngIf="mainRoute === 'supplier' || mainRoute === 'whitelist'">SELLERS</b>
                        <b class="f18" *ngIf="mainRoute === 'invoice'">INVOICES</b>
                        <b class="f18" *ngIf="mainRoute === 'report'">REPORTS</b>
                        <b class="f18" *ngIf="mainRoute === 'staff'">STAFF</b>
                    </div>
                </div>
                <div class="col-6 text-end">
                    <b class="f18">BUYER</b>
                </div>
            </div>
        </div>
    </div>
    <!-- Sidebar -->
    <nav id="sidebar" [ngClass]="{'sidebar-hidden': !isSidebarHidden}">
        <span class="sidebar-toggle-btn" (click)="toggleSidebar()">
            <i [ngClass]="{'fa fa-caret-left': isSidebarHidden, 'fa fa-caret-right': !isSidebarHidden, 'fa': true }" aria-hidden="true"></i>
        </span>
        <ul class="nav flex-column overflow-hidden">
            <li class="nav-item" (click)="toggleSidebar()">
                <a class="nav-link" [ngClass]="{'active': this.mainRoute === 'dashboard'}" [routerLink]="['/dashboard']">
                    <div class="d-flex gap-2">
                        <img src="/assets/icons/dashboard.svg" alt="">
                        Dashboard
                    </div>
                </a>
            </li>
            <li class="nav-item" (click)="toggleSidebar()">
                <a class="nav-link" [ngClass]="{'active': this.mainRoute === 'account'}" [routerLink]="['/account/edit']">
                    <div class="d-flex gap-2">
                        <img src="/assets/icons/staff.svg" alt="">
                        Profile
                    </div>
                </a>
            </li>
            <li class="nav-item" (click)="toggleSidebar()" *ngIf="userService.can(['viewBuyerSupplier']) || userService.can(['viewBuyerWhitelistSupplier'])">
                <a class="nav-link" [ngClass]="{'active': this.mainRoute === 'supplier' || this.mainRoute === 'whitelist' }">
                    <div class="d-flex gap-2">
                        <img src="/assets/icons/repayment.svg" alt="">
                        Sellers
                    </div>
                </a>
            </li>
            <li class="nav-item child-menu" (click)="toggleSidebar()" *ngIf="userService.can(['viewBuyerSupplier'])">
                <a class="nav-link" [ngClass]="{'active': this.mainRoute === 'supplier'}" [routerLink]="['/supplier/list']">
                    <div class="d-flex gap-2">
                        - Onboarded
                    </div>
                </a>
            </li>
            <li class="nav-item child-menu" (click)="toggleSidebar()" *ngIf="userService.can(['viewBuyerWhitelistSupplier'])">
                <a class="nav-link" [ngClass]="{'active': this.mainRoute === 'whitelist'}" [routerLink]="['/whitelist']">
                    <div class="d-flex gap-2">
                        - Whitelisted
                    </div>
                </a>
            </li>
            <li *ngIf="userService.can(['viewBuyerInvoice'])" (click)="toggleSidebar()">
                <a class="nav-link" [ngClass]="{'active': this.mainRoute === 'invoice'}">
                    <div class="d-flex gap-2">
                        <img src="/assets/icons/invoices.svg" alt="">
                        Invoices
                    </div>
                </a>
            </li>
            <li class="nav-item child-menu" (click)="toggleSidebar()" *ngIf="userService.can(['viewBuyerInvoice'])">
                <a class="nav-link" [ngClass]="{'active': this.childRoute == '/invoice/doc/list/all'}" [routerLink]="['/invoice/doc/list/all']">
                    <div class="d-flex gap-2">
                        - All
                    </div>
                </a>
            </li>
            <li class="nav-item child-menu" (click)="toggleSidebar()" *ngIf="userService.can(['viewBuyerInvoice'])">
                <a class="nav-link" [ngClass]="{'active': this.childRoute == '/invoice/doc/list/available'}" [routerLink]="['/invoice/doc/list/available']">
                    <div class="d-flex gap-2">
                        - Available
                    </div>
                </a>
            </li>
            <li class="nav-item child-menu" (click)="toggleSidebar()" *ngIf="userService.can(['viewBuyerInvoice'])">
                <a class="nav-link" [ngClass]="{'active': this.childRoute == '/invoice/doc/list/expired'}" [routerLink]="['/invoice/doc/list/expired']">
                    <div class="d-flex gap-2">
                        - Expired
                    </div>
                </a>
            </li>
            <li class="nav-item child-menu" (click)="toggleSidebar()" *ngIf="userService.can(['viewBuyerInvoice']) && user && user.is_matrix > 0">
                <a class="nav-link" [ngClass]="{'active': this.childRoute == '/invoice/doc/list/pending_check'}" [routerLink]="['/invoice/doc/list/pending_check']">
                    <div class="d-flex gap-2">
                        - Pending Check
                    </div>
                </a>
            </li>
            <li class="nav-item child-menu" (click)="toggleSidebar()" *ngIf="userService.can(['viewBuyerInvoice']) && user && user.is_matrix > 0">
                <a class="nav-link" [ngClass]="{'active': this.childRoute == '/invoice/doc/list/pending_approval'}" [routerLink]="['/invoice/doc/list/pending_approval']">
                    <div class="d-flex gap-2">
                        - Pending Approval
                    </div>
                </a>
            </li>
            <li class="nav-item child-menu" (click)="toggleSidebar()" *ngIf="userService.can(['viewBuyerInvoice'])">
                <a class="nav-link" [ngClass]="{'active': this.childRoute == '/invoice/doc/list/allotted'}" [routerLink]="['/invoice/doc/list/allotted']">
                    <div class="d-flex gap-2">
                        - Scheduled
                    </div>
                </a>
            </li>
            <li class="nav-item child-menu" (click)="toggleSidebar()" *ngIf="userService.can(['viewBuyerInvoice'])">
                <a class="nav-link" [ngClass]="{'active': this.childRoute == '/invoice/doc/list/funding'}" [routerLink]="['/invoice/doc/list/funding']">
                    <div class="d-flex gap-2">
                        - Processing
                    </div>
                </a>
            </li>
            <li class="nav-item child-menu" (click)="toggleSidebar()" *ngIf="userService.can(['viewBuyerInvoice'])">
                <a class="nav-link" [ngClass]="{'active': this.childRoute == '/invoice/doc/list/failed'}" [routerLink]="['/invoice/doc/list/failed']">
                    <div class="d-flex gap-2">
                        - Not Successful
                    </div>
                </a>
            </li>
            <li class="nav-item child-menu" (click)="toggleSidebar()" *ngIf="userService.can(['viewBuyerInvoice'])">
                <a class="nav-link" [ngClass]="{'active': this.childRoute == '/invoice/doc/list/disbursed'}" [routerLink]="['/invoice/doc/list/disbursed']">
                    <div class="d-flex gap-2">
                        - Funded
                    </div>
                </a>
            </li>
            <li class="nav-item" (click)="toggleSidebar()" *ngIf="userService.can(['viewBuyerInvoiceReport'])">
                <a class="nav-link" [ngClass]="{'active': this.mainRoute === 'report'}" [routerLink]="['/report/financing']">
                    <div class="d-flex gap-2">
                        <img src="/assets/icons/reports.svg" alt="">
                        Reports
                    </div>
                </a>
            </li>
            <li *ngIf="userService.can(['viewBuyerUser'])" (click)="toggleSidebar()" class="nav-item">
                <a class="nav-link" [ngClass]="{'active': this.mainRoute === 'staff'}" [routerLink]="['/staff']">
                <div class="d-flex gap-2">
                    <img src="/assets/icons/staff.svg" alt="">
                    Staff
                </div>
                </a>
            </li>
            <li class="nav-item" (click)="toggleSidebar()">
                <a class="nav-link" [ngClass]="{'active': this.mainRoute === 'staff'}" [routerLink]="['/login']">
                <div class="d-flex gap-2">
                    <img src="/assets/images/logout.svg" style="width: 16px;" alt="">
                    <div class="text-gold">Logout</div>
                </div>
                </a>
            </li>
        </ul>
    </nav>

      <!-- Page Content -->
      <div id="content" class="g-0">
          <!-- Your page content goes here -->
          <div class="container-fluid">

          <div id="page-content">
            <router-outlet></router-outlet>
          </div>
          </div>
      </div>
  </div>
<!-- Footer -->
<div id="footer-wrapper" class="shadow-lg">
  <div [ngClass]="isSidebarHidden ? 'footer-container-hidden' : 'footer-container'">
    <div class="row align-items-center h-100">
      <div class="col">
        <span class="text-gray">© Copyright 2024. PILON PTE LTD</span>
      </div>
      <div class="col text-end">
        <span class="text-gray">Powered by Pilon.sg {{env.version}}.{{env.build}}</span>
      </div>
    </div>
  </div>
</div>
</div>
