<!--suppress XmlDuplicatedId -->

<div *ngIf="formField && formField.formControl" class=" row " [ngClass]="formGroupClass">

  <label *ngIf="formField.label!=null && showLabel " class=" col-form-label {{labelClass}} " for="{{formField.name}}">
    <b style="font-size:12px"> {{ labelAfter == false ? formField.label + ':' : ''}}</b>
  </label>

  <div class="{{inputContainerClass}}"
       [ngClass]="'col-12'">
    <!--    <div class="{{inputContainerClass}}"-->
    <!--         [ngClass]="{'col-sm-9': ((inputContainerClass == null && formField.label!=null &&  showLabel )   ), 'col-sm-12':((inputContainerClass == null && formField.label==null) || !showLabel)}">-->

    <ng-container *ngIf="!autocomplete">

      <select *ngIf="tag == 'select' "
              id="{{formField.name}}"
              class="{{this.inputClass}}"

              [formControl]="formField.formControl"
              [ngClass]="{'is-invalid': !formField.isValid()}"
      >
        <option value="{{option.value}}" *ngFor="let option of options.data">{{option.label}}</option>
      </select>

    </ng-container>

    <p-dropdown *ngIf="formField.type=='text' && tag == 'select' && autocomplete" [options]="options.data"
                [filter]="filter" [formControl]="formField.formControl"
                [styleClass]=" !formField.isValid() ? 'is-invalid' : '' "></p-dropdown>


    <input
      *ngIf="(formField.type == 'text' || formField.type == 'email' || formField.type == 'number' || formField.type == 'password' || formField.type == 'confirm-password') && tag == 'input'"
      id="{{formField.name}}"
      [type]="getInputType()"
      class="{{this.inputClass}}"
      placeholder="{{formField.placeHolder}}"
      [formControl]="formField.formControl"
      [ngClass]="{'is-invalid': !formField.isValid()}"
      (change)="valueChange($event)"
      (keypress)="keypress($event)"
    />
    <ng-container *ngIf="formField.type == 'password' && tag == 'input' ">

      <div *ngIf="!noPwStrength && !passwordStr.isValid(formField.getValue())" class="ml-1 mt-1">
        <small
          [ngClass]="{'text-success': passwordStr.isMinLenValid(formField.getValue()), 'text-danger': !passwordStr.isMinLenValid(formField.getValue()) }">
          <i class="fa fa-check" *ngIf="passwordStr.isMinLenValid(formField.getValue())"></i>
          <i class="fa fa-times" *ngIf="!passwordStr.isMinLenValid(formField.getValue())"></i>
          Password must be at least 8 characters
        </small> <br>
        <small
          [ngClass]="{'text-success': passwordStr.hasNumber(formField.getValue()), 'text-danger': !passwordStr.hasNumber(formField.getValue()) }">
          <i class="fa fa-check" *ngIf="passwordStr.hasNumber(formField.getValue())"></i>
          <i class="fa fa-times" *ngIf="!passwordStr.hasNumber(formField.getValue())"></i>
          Must contain at least a number
        </small>
        <br>
        <small
          [ngClass]="{'text-success': passwordStr.hasAlphabet(formField.getValue()), 'text-danger': !passwordStr.hasAlphabet(formField.getValue()) }">
          <i class="fa fa-check" *ngIf="passwordStr.hasAlphabet(formField.getValue())"></i>
          <i class="fa fa-times" *ngIf="!passwordStr.hasAlphabet(formField.getValue())"></i>
          Must contain at least an alphabet
        </small>
        <br>
        <small
          [ngClass]="{'text-success': passwordStr.hasSpecial(formField.getValue()), 'text-danger': !passwordStr.hasSpecial(formField.getValue()) }">
          <i class="fa fa-check" *ngIf="passwordStr.hasSpecial(formField.getValue())"></i>
          <i class="fa fa-times" *ngIf="!passwordStr.hasSpecial(formField.getValue())"></i>
          Must contain at least 1 special character(s) from allowed set !@#$%^&*()_+
        </small>
      </div>
    </ng-container>



    <!--  Date picker is using ngx-bootstrap    -->
    <!--            <input *ngIf="formField.type=='date' && tag == 'input'"-->
    <!--                   id="{{formField.name}}"-->
    <!--                   type="text"-->
    <!--                   class="{{this.inputClass}}"-->
    <!--                   placeholder="YYYY-MM-DD"-->
    <!--                   [formControl]="formField.formControl"-->
    <!--                   [ngClass]="{'is-invalid': !formField.isValid()}"-->
    <!--                   bsDatepicker-->
    <!--                   [bsConfig]="bsConfig"-->
    <!--                   placement="bottom left"-->
    <!--                   autocomplete="off"-->
    <!--            />-->

    <p-calendar styleClass="w-100" dateFormat="dd/mm/yy"
                inputStyleClass="form-control  {{!formField.isValid()?'is-invalid':''}}"
                *ngIf="formField.type=='date' && tag == 'input'" [formControl]="formField.formControl"
                [disabledDates]="disabledDates" [minDate]="minDate" [maxDate]="maxDate"
                (onSelect)="valueChange($event)"></p-calendar>

    <textarea *ngIf="formField.type == 'text' &&  tag=='textarea'"
              [ngClass]="{'is-invalid': !formField.isValid()}"
              [formControl]="formField.formControl"
              class="{{this.inputClass}}"
              rows="{{textarea_rows}}"
    ></textarea>

    <!--      <div *ngIf="formField.type=='boolean' " class="custom-control custom-checkbox">-->
    <!--        <input type="checkbox" id="{{formField.name}}"-->
    <!--               [formControl]="formField.formControl"-->
    <!--               class="custom-control-input"-->
    <!--               data-toggle="toggle" value="1">-->
    <!--        <label for="{{formField.name}}" class="custom-control-label ">-->
    <!--          {{ labelAfter ? formField.label : ''}}-->
    <!--        </label>-->
    <!--      </div>-->


    <p-checkbox *ngIf="formField.type=='boolean' " value="1" [binary]="true"
                [formControl]="formField.formControl"></p-checkbox>


    <!--        <ng-container *ngIf="autocomplete">-->

    <!--            <input *ngIf="formField.type == 'text' && tag == 'input'"-->
    <!--                   id="{{formField.name}}"-->
    <!--                   type="text"-->
    <!--                   class="{{this.inputClass}}"-->
    <!--                   placeholder=""-->
    <!--                   [formControl]="formField.formControl"-->
    <!--                   [ngClass]="{'is-invalid': !formField.isValid()}"-->

    <!--                   [typeahead]="options.rawValues"-->
    <!--                   [typeaheadOptionsLimit]="10"-->
    <!--            />-->

    <!--            <nz-select class="nz-custom-select" [formControl]="formField.formControl"-->
    <!--                       nzAllowClear-->
    <!--                       nzShowSearch-->
    <!--                       nzPlaceHolder="Choose">-->
    <!--                <nz-option *ngFor="let country of options.data"-->
    <!--                           nzValue="{{country.optionValue()}}"-->
    <!--                           nzLabel="{{country.optionLabel()}}" nzCustomContent="true">-->
    <!--                    {{country.optionLabel()}}-->
    <!--                </nz-option>-->
    <!--            </nz-select>-->

    <!--        </ng-container>-->


    <p-calendar *ngIf="formField.type=='datetime' && tag == 'input'" [formControl]="formField.formControl"
                inputStyleClass="form-control  {{!formField.isValid()?'is-invalid':''}}"
                [showTime]="true"
                dateFormat="yy-mm-dd"></p-calendar>

    <!--  DateTime picker is using OWL datetime picker   -->
    <!--        <div *ngIf="formField.type=='datetime' && tag == 'input'" class="input-group">-->
    <!--            <div class="input-group-prepend">-->

    <!--                <button *ngIf="!formField.formControl.disabled" class="btn btn-outline-light-gray" type="button"-->
    <!--                        [owlDateTimeTrigger]="dt1"-->
    <!--                        [disabled]="formField.formControl.disabled"-->
    <!--                >-->
    <!--                    <i class="fa fa-calendar"></i>-->
    <!--                </button>-->

    <!--                <button *ngIf="formField.formControl.disabled" class="btn btn-outline-light-gray" type="button"-->
    <!--                        [disabled]="formField.formControl.disabled"-->
    <!--                >-->
    <!--                    <i class="fa fa-calendar"></i>-->
    <!--                </button>-->

    <!--            </div>-->
    <!--            <input class="form-control bg-white" id="publish_date"-->
    <!--                   placeholder="{{formField.placeHolder}}"-->
    <!--                   [formControl]="formField.formControl"-->
    <!--                   [owlDateTime]="dt1"-->
    <!--                   [owlDateTimeTrigger]="dt1"-->
    <!--                   [ngClass]="{'is-invalid': !formField.isValid()}"-->
    <!--            />-->
    <!--            <div class="clearfix"></div>-->
    <!--            <owl-date-time [pickerMode]="'dialog'" #dt1></owl-date-time>-->
    <!--        </div>-->


  </div>
  <div class="clearfix"></div>

  <!--  <div-->
  <!--    [ngClass]="{'col-sm-9 offset-3': ((inputContainerClass == null && formField.label!=null && showLabel)  ), 'col-sm-12':((inputContainerClass == null && formField.label==null) || !showLabel)}"-->
  <!--    *ngIf="!formField.isValid()">-->

  <div
    [ngClass]="'col-12'"
    *ngIf="!formField.isValid()">

    <span class="text-danger">{{formField.getError()}}</span>
  </div>
</div>
