import {BaseModel} from "./base-model";

export class FundSummary extends BaseModel {
  balance = 0;
  available = 0;
  on_hold = 0;
  ongoing_investment_sum = 0;
  ongoing_investment_count = 0;
  pending_pledge = 0;
  dividends = 0;
  funded_projects = 0;
  expected_next_return = 0;
  annualized_return = "0%";
}

export class Fund extends BaseModel {
}
