import {Pagination} from './pagination';
import _ from 'lodash';
import {BaseModel} from './base-model';

export class BaseObjectList {

    rows = [];
    pagination: Pagination = new Pagination();

    constructor(values: Object = {}) {
        Object.assign(this, values);
        this.setRowNum();
        const _rows = [];

        this.rows.forEach((value, key) => {
            _rows.push(this.makeInstance(value));
        });
        this.rows = _rows;
    }

    makeInstance(value) {
        return new BaseModel(value);
    }

    setRowNum() {
        let rowNo = this.pagination.firstRowNo || 0;
        if (this.rows.length > 0) {
            _.map(this.rows, row => {
                row.row_num = rowNo--;
                return row;
            });
        }
    }

    removeFromList(obj) {

        const index = this.rows.indexOf(obj);
        this.rows.splice(index, 1);

    }

    clear() {
        this.rows = [];
        this.pagination = new Pagination();
    }
}
