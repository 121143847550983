export class BaseModel {

  row_num: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
    this.init(values);
  }

  init(value) {
  }

  setValues(values) {
    Object.assign(this, values);
    this.init(values);
  }
}
