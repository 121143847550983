import {Component, HostListener, OnInit} from '@angular/core';
import {SettingDataService} from "../../model/setting-data.service";
import {MessageService} from "primeng";
import {StatsService} from "../../model/stats.service";
import {Stats} from "../../model/stats";
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {DeviceDetectorService} from "ngx-device-detector";
import {environment} from "../../../environments/environment";
import {UserService} from "../../model/user.service";
import {User} from "../../model/user";

declare var ga: Function;

@Component({
  selector: 'app-sidenav-layout',
  templateUrl: './sidenav-layout.component.html',
  styleUrls: ['./sidenav-layout.component.scss']
})
export class SidenavLayoutComponent implements OnInit {
  env = environment;

  isSidebarHidden: boolean = false;
  updateStatusTimer = [];
  public stats: Stats;

  sidebarExpand = true;
  overlayEnabled = false;
  isMobile = false;
  matrixInfo: { maker: number, checker: number, approver: number };
  user: User;
  mainRoute: string;
  childRoute: string;
  currentChildRoute: string;
  filterParam: string;

  constructor(
    private settingDataService: SettingDataService,
    private messageService: MessageService,
    // private statsService: StatsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    public userService: UserService,
  ) {

    this.settingDataService.refreshGlobalSettings();
    this.isMobile = this.deviceService.isMobile();
    // const innerWidth = window.innerWidth;
    // console.log(innerWidth)

    if (this.isMobile) {
      this.overlayEnabled = true;
    }

    if (this.overlayEnabled) {
      this.router.events.subscribe((val) => {
        this.sidebarExpand = false;
      });
    }

    if (environment.ga_id) {
      ga('create', environment.ga_id, 'auto');
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (environment.ga_id) {
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview');
        }
      }
        this.mainRoute = this.activatedRoute.firstChild.routeConfig?.path || "";
        this.childRoute = this.router.url || "";
    });

    this.userService.getMe({expand: 'matrix'}).subscribe(
      user => {

        this.user = user;

        if (user.matrix) {
          this.matrixInfo = user.matrix;
        }

        this.userService.setPermissions(user._permissions);

        if (user.reset_password_token) {

          this.router.navigate(['password-reset'], {
            queryParams: {
              token: user.reset_password_token,
              'req': 1
            }
          });

        }

      }
    )

  }

  private getChildRoutePath(route: ActivatedRoute): string {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.routeConfig.path;
  }

  toggleSidebar() {
    this.isSidebarHidden = !this.isSidebarHidden;
  }


  ngOnInit(): void {

    // this.getList();

    // this.invoiceService.observerProcessInvoice().subscribe(
    //   invoice => {
    //     if (invoice) {
    //       console.log(invoice.id);
    //       console.log('existing process', typeof this.updateStatusTimer[invoice.id] != 'undefined');
    //       if (typeof this.updateStatusTimer[invoice.id] == 'undefined') {
    //         this.checkUpdate(invoice);
    //       }
    //     }
    //   }
    // )
    //
    // setTimeout(() => {
    //   this.statsService.updateTotals();
    // }, 1000 * 30);
    //
    // this.statsService.observeTotals().subscribe(
    //   stats => {
    //     this.stats = stats;
    //   }
    // );


  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   const innerWidth = window.innerWidth;
  //   console.log(innerWidth)
  // }

  // getList() {
  //
  //   if (this.updateStatusTimer) {
  //     this.updateStatusTimer.forEach(timer => {
  //       clearInterval(timer);
  //     })
  //   }
  //
  //   this.invoiceService.getList({filter: 'pending-request'}).subscribe(_invoiceList => {
  //
  //       _invoiceList.rows.forEach((inv: Invoice, index) => {
  //         if (inv.isPendingRequest() && !inv.hasVerification()) {
  //           this.checkUpdate(inv);
  //           this.statsService.updateTotals();
  //         }
  //       });
  //
  //     },
  //     error => {
  //
  //       console.log(error)
  //       // this.loading = false;
  //
  //     });
  //
  // }


  // checkUpdate(invoice) {
  //
  //   // const invoice = this.invoiceList.rows[index];
  //   const id = invoice.id;
  //
  //   if (typeof this.updateStatusTimer[id] != 'undefined') {
  //     clearInterval(this.updateStatusTimer[id]);
  //   }
  //
  //   console.log('Will monitor update ' + invoice.id);
  //   this.updateStatusTimer[id] = setInterval(() => {
  //
  //     console.log('Checking update... ' + invoice.id);
  //     this.invoiceService.getById(id).subscribe(
  //       _inv => {
  //
  //         if (!_inv.isPendingRequest() && _inv.hasVerification()) {
  //           console.log('Clearing... ' + invoice.id);
  //           clearInterval(this.updateStatusTimer[id]);
  //
  //           this.invoiceService.doneProcess.next(invoice);
  //
  //           this.messageService.add({
  //             key: 'topright',
  //             severity: 'success',
  //             summary: 'Success',
  //             detail: '' + _inv.invoice_no + ' successfully enlisted for funding'
  //           });
  //
  //         }
  //       }
  //     )
  //
  //
  //   }, 5000);
  //
  // }

}
