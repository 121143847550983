import {BaseModel} from "../../../model/base-model";

class OrderItem extends BaseModel {
  code: string;
  description: string;
  sales_order: string;
  qty: string;
}


class InvoiceData extends BaseModel {
  order_items: OrderItem[];
  activities: any[];

  init(value) {
    super.init(value);
  }
}

export class Invoice extends BaseModel {

  id: string;
  invoice_no: string;
  amount: number;
  due_date: string;
  data: InvoiceData;
  created_at: string;
  status: number;
  status_label: string;

  reference_no: string;


  init(value) {
    super.init(value);
  }

}

