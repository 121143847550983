


export class PasswordStrength{

    hasAlphabet(val){
        return val.search(/[a-z-A-Z]/) !== -1;
    }

    hasSpecial(val){
      // return /[~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(val);
      return /[!\@#\$\%\^&\*\(\)\_\+]/g.test(val);
      // return /[^a-z0-9 ]/g.test(val);
    }

    hasNumber(val){
        return val.search(/[0-9]/) !== -1;
    }

    isMinLenValid(val){
        return val.length >= 8;
    }

    isMaxLenValid(val){
        return val.length <= 20;
    }

    isValid(val){
      return (this.hasAlphabet(val) && this.hasNumber(val) && this.isMinLenValid(val) && this.hasSpecial(val)  );
    }

}
