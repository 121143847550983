import {BaseModel} from "./base-model";

export class GlobalSettings extends BaseModel {
  currency_code: string;
  currency_symbol: string;
  minimum_investment_amount: string;
  risk_management_fee: string;
  minimum_deposit: string;
  deposit_bank_account: string;

  // dynamics
  availability_start: number;
  availability_end: number;
}
