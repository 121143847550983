import {catchError, map} from 'rxjs/operators';


import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {GlobalService} from './global.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {ResponseBody} from './response-body';

@Injectable()
export class DataListService {

  public qryDataList: any = {};
  public dataList: any = {};

  constructor(
    private http: HttpClient
  ) {
  }

  get(expand): Observable<any> {

    const subjectBehavior = new BehaviorSubject({});

    let qryDataListArray: string[];

    if (expand instanceof Array) {
      qryDataListArray = expand;
    } else {
      qryDataListArray = expand.split(',');
    }

    const returnDataList = {};

    const query = [];
    qryDataListArray.forEach((key) => {
      this.qryDataList[key] = 0;
      if (typeof this.dataList[key] !== 'undefined') {
        returnDataList[key] = this.dataList[key];
      } else {
        query.push(key);
      }
    });

    if (query.length === 0) {

      return of(returnDataList);

    } else {

      return this.http.get<ResponseBody>(
        GlobalService.getUrl('/data-list', {expand: query.join(',')}),
        {headers: GlobalService.getHeaders()}).pipe(
        map(result => {

            // console.log('getDataList result', result);
            qryDataListArray.forEach((key) => {
              if (typeof result.data[key] !== 'undefined') {
                this.dataList[key] = result.data[key];
                returnDataList[key] = result.data[key];
              }
            });
            return returnDataList;

          },
          catchError(GlobalService.handleError)
        ));

    }

  }


  getHolidays(query) {
    // {{ apiUrl }}

    return this.http.get<ResponseBody>(
      GlobalService.getUrl('/holiday/date-listing', query),
      {headers: GlobalService.getHeaders()}).pipe(
      map(result => {

          return result.data;

        },
        catchError(GlobalService.handleError)
      ));

  }
}
