import {catchError, map} from 'rxjs/operators';

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import {GlobalService} from './global.service';
import {ResponseBody} from './response-body';
import {GlobalSettings} from "./global-settings.";

@Injectable()
export class SettingDataService {
  constructor(
    private globalService: GlobalService,
    private http: HttpClient
  ) {
  }

  settingsBehavior = new BehaviorSubject(null);
  settingsObservable = this.settingsBehavior.asObservable();

  // GET /v1/setting/public
  getAllSettingsPublic(): Observable<any> {
    const headers = GlobalService.getHeaders();
    return this.http
      .get<ResponseBody>(this.globalService.apiHost + '/setting/public', {
        // headers: headers
      }).pipe(
        map(response => {
          return response.data;
        }),
        catchError(GlobalService.handleError));
  }

  getAllSettingsGlobal(): Observable<GlobalSettings> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(this.globalService.apiHost + '/setting/global', {
        headers: headers
      }).pipe(
        map(response => {
          return response.data;
        }),
        catchError(GlobalService.handleError));
  }

  refreshGlobalSettings() {

    this.settingsObservable = new BehaviorSubject(null);
    this.getAllSettingsGlobal().subscribe(settings => {
      sessionStorage.setItem(
        'global-settings',
        JSON.stringify(settings)
      );
      this.settingsBehavior.next(settings);
    });

  }

}
