import {Injectable} from '@angular/core';

import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {throwError} from 'rxjs/internal/observable/throwError';
import {AppInjector} from "./app-injector";
import {UrlFormatter} from "../shared/formatter/url-formatter";
import {GlobalSettings} from "./global-settings.";
import {ErrorResponse} from "./response-body";
import {modalError} from "../shared/modal-helper";
import {SharedService} from "../shared/shared.service";
import {JwtHelperService} from "@auth0/angular-jwt";


export const ERROR_CODES = {
  CODE_ERROR_FIELDS: 4220
};

@Injectable()
export class GlobalService {
  public apiHost: string;

  public setting: GlobalSettings;

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService,

  ) {
    this.apiHost = environment.apiHost;
  }

  static _url(host, endpoint, queryParams = null) {

    if (queryParams !== null) {
      if (endpoint.search('\\?') === -1) {
        return host + endpoint + '?' + UrlFormatter.serializeQueryString(queryParams) +
          '&' + GlobalService.debugString();
      } else {
        return host + endpoint + '&' + UrlFormatter.serializeQueryString(queryParams) +
          '&' + GlobalService.debugString();
      }
    } else {
      return host + endpoint + '?' + GlobalService.debugString();
    }

  }

  static getUrl(url, queryParams = null) {
    return this._url(environment.apiHost, url, queryParams);
  }

  static getInvoiceNftApi(url, queryParams = null) {
    return this._url(environment.apiInvoiceHost, url, queryParams);
  }

  static debugString() {
    let xdebug = '';
    if (environment.xdebug) {
      xdebug = 'XDEBUG_SESSION_START=PHPSTORM';
    }
    return xdebug;
  }

  static getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + GlobalService.getToken()
    });
  }

  static getToken(): any {
    return localStorage.getItem(environment.tokenName);
  }

  static getSettings(): GlobalSettings {
    const setting = new GlobalSettings();
    if (sessionStorage.getItem('global-settings') !== null) {
      const _setting = JSON.parse(sessionStorage.getItem('global-settings'));
      if (_setting) {
        Object.assign(setting, _setting);
      }
    }
    return setting;
  }

  static _handleError(response: Response | any, modal = false) {

    const sharedService = AppInjector.get(SharedService);
    let errorMessage: ErrorResponse;

    // Connection error
    if (typeof response.error === 'undefined' || response.error.status === 0) {

      errorMessage = new ErrorResponse({
        message: 'Sorry, there was a connection error occurred. Please try again.',
        code: 0,
      });

    } else {

      errorMessage = new ErrorResponse(response.error.data);
      // if (errorMessage.status === 401 || errorMessage.status === 403) {
      if (errorMessage.status === 401 || errorMessage.status === 403) {

        if (errorMessage.status === 401) {
          sharedService.unauthorizedAccess(response.error);
        } else {
          modalError(errorMessage.message);
        }
        errorMessage = response.error;

      } else if (modal && errorMessage.code != ERROR_CODES.CODE_ERROR_FIELDS) {
        modalError(errorMessage.message);
      }

    }

    return throwError(errorMessage);

  }

  // this function will show error on modal display automatically, if the error code is not 4220
  static handleErrorModal(response: Response | any) {
    return GlobalService._handleError(response, true);
  }

  static handleError(response: Response | any) {
    console.log(response)
    return GlobalService._handleError(response);
  }

  // loadGlobalSettingsFromSessionStorage(): void {
  //   if (sessionStorage.getItem('global-settings') != null) {
  //     this.setting = JSON.parse(sessionStorage.getItem('global-settings'));
  //   }
  // }
  isLoggedIn(): boolean {
    return this.jwtHelper.isTokenExpired() !== true;
  }

}
