import {catchError, map} from 'rxjs/operators';

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {GlobalService} from './global.service';
import {ResponseBody} from './response-body';

import {BehaviorSubject, Observable} from 'rxjs';
import { UserList} from './user';

@Injectable()
export class UserDataService {

  private userDataSource = new BehaviorSubject({});

  constructor(
    private globalService: GlobalService,
    private router: Router,
    private http: HttpClient,
  ) {

  }

  getUsers(query={}): Observable<UserList> {
    const headers = GlobalService.getHeaders();

    return this.http
      .get<ResponseBody>(
        GlobalService.getUrl('/user', query),
        {headers: headers}
      ).pipe(
        map(response => {
          return new UserList(response.data);
        }),
        catchError(GlobalService.handleError));
  }

  getSupplierAsSelect(extendedQueries?: any): Observable<any> {

    let queries = {};
    if (extendedQueries) {
      queries = Object.assign(queries, extendedQueries);
    }
    return this.http.get<ResponseBody>(
      GlobalService.getUrl('/invoice/document/suppliers', queries),
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(
        response => {
          return <any>response.data;
        }
      ),
      catchError(GlobalService.handleError)
    );

  }
  getAllUsersDownload(extendedQueries?: any) {

    let queries = {};
    if (extendedQueries) {
      queries = Object.assign(queries, extendedQueries);
    }

    return this.http.get<ResponseBody>(
      GlobalService.getUrl('/user?download=1', queries),
      {headers: GlobalService.getHeaders()}
    ).pipe(
      map(
        response => {
          return response;
        }
      ),
      catchError(GlobalService.handleError)
    ).subscribe(
      response => {

        this.downLoadFile1(response.data, 'text/csv', queries['type'])

      }
    );

  }
  downLoadFile1(data: any, type: string, filename: string) {

    // const a = document.createElement("a") as Anch;
    const a = document.createElement("a");
    document.body.appendChild(a);
    // a.style = "display: none";

    const blob = new Blob([data], {type: "text/csv"}),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'supplier_lists.csv';

    a.click();
    window.URL.revokeObjectURL(url);


  }


}
