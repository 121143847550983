
export class Fs {

    row_num: number;

    id: number;
    filename: string;
    url: string;
    status: number;
    is_image: boolean;
    status_label: string;
    created_at: string;
    updated_at: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

}