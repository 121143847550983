<div *ngIf="!showInput">
  <input type="file" id="fileInput-{{_id}}"
         (change)="onFileChange($event)"
         style="visibility: hidden;height: 1px;width: 1px" >
</div>

<div class="custom-file"  *ngIf="showInput">

  <input type="file" id="fileInput-{{_id}}"
         class="form-control"
         (change)="onFileChange($event)"
         [ngClass]="{'is-invalid': !isValid() }"

  >
  <label class="custom-file-label" for="fileInput-{{_id}}"
         [ngClass]="{'is-invalid': !isValid() }">{{ selectedFile ? selectedFile.name : 'Choose file'  }}</label>
</div>

<p *ngIf="uploading">
  <!--    <progressbar type="success" [value]="uploadProgress"></progressbar>-->
  <p-progressBar mode="indeterminate"></p-progressBar>
</p>


<p *ngIf="currentFile && showPreview"  style="margin-top:10px">
  <img *ngIf="currentFile.is_image" [src]="currentFile.url" alt="" style="width: 200px">
  <a *ngIf="!currentFile.is_image" [href]="currentFile.url" target="_blank">View</a>
</p>
