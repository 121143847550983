import {BaseModel} from "./base-model";

export const BankStatic = {

  STATUS_DRAFT: 0,
  STATUS_PENDING_APPROVAL: 1,
  STATUS_APPROVED: 2,
  STATUS_REJECTED: 3,
  STATUS_ACTIVE: 4,

}

export class Bank extends BaseModel {

  id: string;
  bank_name: string;
  branch_code: string;
  account_number: string;
  account_name: string;
  reject_reason: string;

  type_label: string
  status: number
  status_label: string

  isRejected() {
    return this.status == BankStatic.STATUS_REJECTED;
  }

  isPendingApproval() {
    return this.status == BankStatic.STATUS_PENDING_APPROVAL;
  }

  canEdit() {
    return  this.status == BankStatic.STATUS_DRAFT || this.status == BankStatic.STATUS_REJECTED;
  }

  isApproved() {
    return this.status == BankStatic.STATUS_APPROVED;
  }


  getKycStatusClass() {
    return {
      'text-success': this.status === BankStatic.STATUS_APPROVED,
      'text-warning': (this.status === BankStatic.STATUS_PENDING_APPROVAL),
      'text-danger': (this.status === BankStatic.STATUS_REJECTED),
    };
  }
}
