import {BaseObjectList} from "../../../model/base-object-list";
import {InvDocument} from "./inv-document";

export class InvDocumentList extends BaseObjectList {

  rows: InvDocument[];
  grand_total: number;
  total_per_page: number;

  makeInstance(value) {
    return new InvDocument(value);
  }
}
