import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SpinnerComponent} from './spinner/spinner.component';
import {CommonModule} from "@angular/common";
import {UploaderComponent} from "./uploader/uploader.component";
import {CheckboxModule} from 'primeng/checkbox';
import {RouterModule} from "@angular/router";
import {
  AbbreviateNumberPipe,
  CurrencyFormatPipe,
  DecimalFormatPipe,
  NumberFormatPipe,
} from "./formatter/number-format.pipe";
import { FormGroupComponent } from "./views/form-group/form-group.component";
import {
  CalendarModule,
  DropdownModule,
  MessageService,
  ProgressBarModule,
  ToastModule,
} from "primeng";
import { AppModalService } from "./modal-helper";
import { FsDataService } from "../model/fs-data.service";
import { DataListService } from "../model/data-list.service";
// @ts-ignore
import { MomentModule } from "ngx-moment";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    NgbPaginationModule,
    // ProgressbarModule.forRoot(),
    RouterModule,
    CheckboxModule,
    CalendarModule,
    ProgressBarModule,
    DropdownModule,

    ToastModule,
  ],
  declarations: [
    SpinnerComponent,
    // TimepickerComponent,
    UploaderComponent,
    NumberFormatPipe,
    AbbreviateNumberPipe,
    FormGroupComponent,
    CurrencyFormatPipe,
    DecimalFormatPipe,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    SpinnerComponent,
    // PaginationModule,
    // TimepickerComponent,
    UploaderComponent,

    FormGroupComponent,
    NumberFormatPipe,
    AbbreviateNumberPipe,
    CurrencyFormatPipe,

    DecimalFormatPipe,

    ToastModule,
  ],
  providers: [AppModalService, FsDataService, DataListService, MessageService],
})
export class SharedModule {}
