import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-spinner',
    template: `
      <i class="spinner-{{size}}"></i>
  `,
    styleUrls: ['spinner.component.scss']
})
export class SpinnerComponent {
  @Input() size = 'sm';
}
