import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Fs} from "../../model/fs";
import {FsDataService} from "../../model/fs-data.service";
import {FormControl} from "@angular/forms";
import {Observable, Subscription} from "rxjs";

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {

  _id: number = 0;

  @Input() appFormControl: FormControl;
  @Input() fileClass;
  @Input() currentFile: Fs;
  @Input() events: Observable<string>;
  @Input() eventsSubscription: any;

  @Input() hideDefaultTrigger = false;
  @Input() showPreview = true;
  @Input() showInput = true;

  @Input() imageOnly = false;
  @Input() imageCrop = false;
  @Input() imageSize = ''; // ex: 100x100

  @Output() change: EventEmitter<any> = new EventEmitter();

  uploading = false;
  uploadProgress = 0;
  selectedFile: any;

  showAllError = false;

  constructor(
    private fsDataService: FsDataService
  ) {

    this._id = Math.round(Math.random() * 100000);
  }

  ngOnInit() {

    if (this.events) {
      this.eventsSubscription = this.events.subscribe((str) => {
        if (str === 'showAllError') {
          this.showAllError = true;
        }
      });
    }

  }

  isValid() {
    if (this.showAllError || this.appFormControl.touched) {
      return this.appFormControl.valid;
    }
    return true;
  }

  onFileChange(event) {

    if (event.target.files && event.target.files.length) {

      this.uploadProgress = 0;
      this.uploading = true;

      const [file] = event.target.files;

      this.selectedFile = file;

      this.fsDataService.upload(this.selectedFile, 1000)
        .subscribe(_file => {

          if (_file instanceof Fs) {

            this.currentFile = _file;
            this.appFormControl.setValue(_file.id);

            // this.change.emit([_file.id]);
            this.change.emit([_file]);

          } else {
            this.uploadProgress = _file;
          }

          setTimeout(() => {
            this.uploading = false;
          }, 1000);

        }, error => {

          console.log(error);

        }, () => {

          setTimeout(() => {
            this.uploading = false;
          }, 1000);

        });

    }

  }

  openFileUpload() {
    const element: HTMLElement = document.getElementById('fileInput-' + this._id) as HTMLElement;
    element.click();
  }

  getSrc() {
    if (this.currentFile) {
      if (this.imageSize) {
        return this.currentFile.url + '&size=' + this.imageSize;
      } else {
        return this.currentFile.url;
      }
    }
    return '';
  }
}
