import {Pipe, PipeTransform} from '@angular/core';
import {NumberFormatter} from './number-formatter';
import {GlobalService} from '../../model/global.service';

@Pipe({name: 'numberFormat'})
export class NumberFormatPipe implements PipeTransform {
  transform(value: number): string {
    return NumberFormatter.format(value, 0);
  }
}

@Pipe({
  name: "abbreviateNumber",
})
export class AbbreviateNumberPipe implements PipeTransform {
  transform(value: number): string {
    if(value >= 10000000000){
        return Math.floor(value / 1000000000) + "B";
    }else if (value >= 1000000) {
      return Math.floor(value / 1000000) + "M";
    } else if (value >= 1000) {
      return Math.floor(value / 1000) + "K";
    } else {
      return value.toString();
    }
  }
}

@Pipe({name: 'decimalFormat'})
export class DecimalFormatPipe implements PipeTransform {
  transform(value: number, decimal = 2): string {
    return NumberFormatter.format(value, decimal);
  }
}

@Pipe({name: 'currencyFormat'})
export class CurrencyFormatPipe implements PipeTransform {
  transform(value: number, decimal = 2): string {
    const settings = GlobalService.getSettings();
    if (settings && settings.currency_symbol) {
      return settings.currency_symbol + ' ' + NumberFormatter.format(value, decimal);
    } else {
      return NumberFormatter.format(value, decimal);
    }
  }
}

// @Pipe({name: 'currencyFormat2'})
// export class CurrencyFormat2Pipe implements PipeTransform {
//     // transform(value: number, decimal = 2): Observable<any> {
//     transform(value: number, decimal = 2): string {
//
//         const subjectBehavior = new BehaviorSubject({});
//         let val = '';
//         setTimeout(() => {
//             val = 'aa' + value;
//             // subjectBehavior.next('aa' + value);
//         }, 1000);
//         // return subjectBehavior.asObservable();
//         return val;
//
//     }
// }
