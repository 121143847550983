import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SimpleLayoutComponent} from "./containers/simple-layout/simple-layout.component";
import {SidenavLayoutComponent} from "./containers/sidenav-layout/sidenav-layout.component";
import {AuthGuard} from "./model/auth.guard";

const routes: Routes = [
  {
    path: 'login',
    component: SimpleLayoutComponent,
    loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    component: SimpleLayoutComponent,
    loadChildren: () => import('./views/signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: 'password-reset-request',
    loadChildren: () => import('./views/password-reset-request/password-reset-request.module').then(m => m.PasswordResetRequestModule)
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./views/password-reset/password-reset.module').then(m => m.PasswordResetModule)
  },
  {
    path: 'confirm',
    loadChildren: () => import('./views/confirm/confirm.module').then(m => m.ConfirmModule)
  },
  {
    path: 'onboarding',
    component: SimpleLayoutComponent,
    loadChildren: () => import('./views/onboarding/onboarding.module').then(m => m.OnboardingModule)
  },
  {
    path: '',
    component: SidenavLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'account',
        loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      // {
      //   path: 'dashboard',
      //   pathMatch: 'full',
      //   redirectTo: '/invoice/doc/list/available',
      // },
      {
        path: 'invoice',
        loadChildren: () => import('./modules/invoice/invoice.module').then(m => m.InvoiceModule)
      },
      {
        path: 'supplier',
        loadChildren: () => import('./modules/supplier/supplier.module').then(m => m.SupplierModule)
      },
      {
        path: 'whitelist',
        loadChildren: () => import('./modules/whitelist/whitelist.module').then(m => m.WhitelistModule)
      },
      {
        path: 'report',
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'bank-account',
        loadChildren: () => import('./views/bank-details/bank-details.module').then(m => m.BankDetailsModule)
      },
      {
        path: 'staff',
        loadChildren: () => import('./modules/staff/staff.module').then(m => m.StaffModule)
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

